<template>
  <div class="me">
    <div class="user-msg">
      <div class="quit" v-if="isLogin" @click="quit">
        <img src="../../assets/quit.svg" alt="" />
        <p>退出登录</p>
      </div>
      <div class="user">
        <img :src="userInfo.headImgUrl?userInfo.headImgUrl:'../../assets/user.png'" alt="" />
        <p v-if="isLogin">{{userInfo.frontNum}} **** {{userInfo.afterNum}}</p>
        <p v-else @click="login">登录/注册</p>
      </div>
    </div>
    <div class="myServer">
      <h3>我的服务</h3>
      <div class="list">
        <div class="item" @click="myCollection">
          <img src="../../assets/collection.png" alt="" />
          <p>我的收藏</p>
        </div>
        <div class="item" @click="subscriptionManagement">
          <img src="../../assets/subscription.png" alt="" />
          <p>订阅管理</p>
        </div>
        <div class="item" @click="call">
          <img src="../../assets/my-call.png" alt="" />
          <p>我的客服</p>
        </div>
        <div class="item"  @click="instruction">
          <img src="../../assets/instructions.png" alt="" />
          <p>使用说明</p>
        </div>
      </div>
    </div>
     <van-popup v-model="show">
        <div class="callMsg">
          <img :src="shopMsg.shopkeeperContact" alt="">
        </div>
      </van-popup>
    <tabBar :shopAlias="shopMsg.shopAlias" />
  </div>
</template>

<script>
import { getShopName, getUserInfo, logout } from "../../utils/api";
import tabBar from "../../component/tabBar";
import { removeLocal } from "../../../static/js/publicMethod"
export default {
  components: { tabBar },
  data() {
    return {
      shopId: null,
      shopMsg: {},
      isLogin:null,
      userInfo:{},
      show:false,
    };
  },
  async created() {
    await this.getshopName();
    await this.getUserInfo();
  },
  methods: {
    //我的客服
    call(){
      this.show = true;
    },
    //订阅管理
    subscriptionManagement(){
      this.$toast('暂未开放')
    },
    //使用说明
    instruction(){
      this.$router.push({
        path:`/${this.shopMsg.shopAlias}/instruction`
      })
    },
    //登录
    login(){
      this.$router.push({
        path:`/${this.shopMsg.shopAlias}/login`
      })
    },
    //获取用户信息
    async getUserInfo() {
      await getUserInfo().then((res) => {
        console.log(res);
        if(res){
          this.isLogin = true;
          this.userInfo = res.data;
          this.userInfo.afterNum = this.userInfo.phoneNumber.substring(this.userInfo.phoneNumber.length-4);
          this.userInfo.frontNum = this.userInfo.phoneNumber.substring(0,3);
        }else{
          this.isLogin = false;
        }
      });
    },
    //获取店铺信息
    async getshopName() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      await getShopName().then((res) => {
        this.shopMsg = res;
        this.shopId = res.id;
        this.$toast.clear();
      });
    },
    //退出
    quit() {
      this.$dialog.confirm({
        title: "提示",
        message: "您确认退出？",
      })
        .then(() => {
          // on confirm
          logout().then((res) => {
            console.log(res);
            this.getUserInfo();
            removeLocal("token")
            
            this.$toast.success('退出成功')
          });
        })
        .catch(() => {
          // on cancel
        });
      
      // this.$cookies.remove("SESSION")
    },
    //我的收藏
    myCollection() {
      this.$router.push({
        path: `/${this.shopMsg.shopAlias}/myCollection`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #ff8757;
.callMsg{
  padding: 10px;
  img{
    width: 100%;
  }
}
.user-msg {
  width: 100%;
  height: 166px;
  background: url("../../assets/user-bg.png") #fffaf6 91% 13px no-repeat;
  border-radius: 0px 0px 20px 20px;
  background-size: 36%;
  position: relative;
  .quit {
    position: absolute;
    z-index: 100;
    right: 15px;
    top: 21px;
    cursor: pointer;
    display: flex;
    align-items: center;
    img {
      width: 14px;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 12px;
      margin-left: 5px;
    }
  }
  .user {
    display: flex;
    height: 166px;
    align-items: center;
    img {
      margin-left: 27px;
      width: 70px;
      height: 70px;
      border-radius: 36px;
    }
    p {
      margin-left: 15px;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
    }
  }
}
.myServer {
  width: 100%;
  padding: 20px 15px 0;
  h3 {
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    line-height: 20px;
    margin-bottom: 22px;
  }
  .list {
    width: 100%;
    display: flex;
    flex-flow: wrap;
    .item {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 6%;
      margin-bottom: 30px;
      img {
        width: 26px;
      }
      p {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        line-height: 12px;
        margin-top: 10px;
      }
    }
    .item:nth-child(4n) {
      margin-right: 0;
    }
  }
}
</style>